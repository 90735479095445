.c-wizard{
    & .wizard-steps{
        height: 100vh;
    }
/*     &__stepTwo{
        opacity: 0;
        animation: appearStep 1s 1s forwards;
        animation-name: appearStep;
        animation-duration: 1s;
        animation-delay: 1s;
        animation-fill-mode: forwards;
    } */
}
@keyframes appearStep {
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

.c-wizard-info{
    display: flex;
    flex-direction: column;
    background: var(--brand-color);
    padding: 3rem;
    justify-content: center;
    height: 100%;
    min-height: 100vh;

    &__header{
        display: flex;
        flex-direction: column;
        text-align: center;
        color: white;
        align-items: center;
        margin-top: 7rem;
        margin-bottom: 1rem;

        @include for-laptop-only{
            margin-top: 0;

        }
    }
    &__logo{
        width: 8rem;
        height: 8rem;
        border-radius: 50%;
        overflow: hidden;
        box-shadow: 0px 3px 6px #0000004d;
        margin-bottom: 1rem;
        & img{
            width: 100%;
            object-fit: cover;
        }
    }
    &__title{
        font-size: 18px;
        font-weight: 800;
        margin-bottom: 0px;
        color: white;
        text-align: center;
    }
    &__text{
        font-size: 13px;
        color: white;
        text-align: center;
    }
    &__body{
        margin-top: 1rem;
        max-width: 50rem;
        margin: 0 auto;
        width: 100%;
    }
    &__illustration{
        max-width: 29rem;
        margin-top: 5rem;
    }
    &__step{
        text-align: center;
        color: white;
        font-size: 13px;
        font-weight: 800;
        text-transform: uppercase;
    }
    &__description{
        text-align: center;
        color: white;
        font-size: 13px;
        font-weight: 800;
        max-width: 22rem;
        margin: 0 auto;
        margin-bottom: 4.5rem;
        @include for-laptop-only{
            margin-bottom: 1.5rem;
        }
    }
    &__form{
        position: relative;

        & input{
            height: 6rem;
            width: 100%;
            padding: 1rem;
            font-size: 15px;
            border-radius: 5px;
            border: 0;
            text-align: center;
        }

        & i{
            position: absolute;
            left: 2.5rem;
            font-size: 35px;
            color: #CDCDCD;
            top: 1.3rem;
        }
    }
    &__footer{
        margin-top: auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}

.c-wizard-form{
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: auto;
    background: white;
    &__header{
        display: flex;
        gap: 2rem;
        padding: 4rem;
        position: relative;
        max-width: 80rem;
        margin: 0 auto;
        width: 100%;
        @include respond(350){
            padding: 2rem;
        }

        & .c-btn{
            position: absolute;
            right: 4rem;
            bottom: -2rem;        
        }
    }
    &__subheader{
        padding: 0rem 3rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        max-width: 80rem;
        margin: 0 auto;
        width: 100%;
        @include respond(350){
            padding: 0rem 2rem;
        }
        &__subtitle{
            font-size: 13px;
            font-weight: 700;
            text-transform: uppercase;
        }
        &__title{
            font-size: 20px;
            font-weight: 800;
            line-height: 30px;
        }
        &__phone{
            background: #F2F2F2;
            padding: 1rem;
            display: flex;
            justify-content: space-between;
            font-size: 15px;
            color: #7E7E7E;

            & b{
                color: var(--text-color);
                display: flex;
                gap: 0.5rem;
            }
        }
    }
    &__logo{
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        overflow: hidden;
    }
    &__subtitle{
        color: var(--danger-color);
        font-size: 10px;
        font-weight: 900;
        line-height: 11px;
        margin-top: 1rem;

        &--normalFont{
            color: var(--normal-size);
            font-weight: 400;
            font-size: 13px;
        }
    }
    &__title{
        font-size: 20px;
        font-weight: 800;
    }
    &__body{
        flex: 1;
        padding: 2rem;
        max-width: 50rem;
        margin: 0 auto;
        width: 100%;
        margin-bottom: 12rem;
    }
    &__footer{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 3rem;
        background: var(--brand-color);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__service{
        background: #e8e8e8;
        padding: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        left: 0;
        margin-left: -2rem;
        margin-right: -2rem;
        margin-top: 3rem;
        border-radius: 1rem;
        @include respond(450){
            border-radius: 0;
        }

        &__title{
            display: flex;
            gap: 1rem;
            align-items: center;
            font-weight: 900;
            color: #8594A0;
            font-size: 13px;

            & span{
                font-size: 25px;
            }
        }
        &__info{
            & p{
                margin: 0;
                font-size: 13px;
                color: #8594A0;
                text-align: right;

                & span{
                    font-size: 10px;
                    margin-left: 1rem;
                }
            }
        }
        & span::before{
            color: var(--brand-color);
        }
    }
}

.c-wizard-success{
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 2rem;
    background: var(--brand-color);
    color: white;
    &__logo{
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        overflow: hidden;
        box-shadow: 0px 3px 6px #0000004d;
    }
    &__body{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &__title{
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 1rem;
    }
    &__text{
        max-width: 25rem;
        text-align: center;
        font-size: 15px;
    }
    &__icon{
        font-size: 6rem;
        margin-bottom: 2rem;
        &::before{
            color: white !important;
        }
    }
    &__footer{
        display: flex;
        justify-content: center;
        align-items: center;
        & button{
            max-width: 45rem;
        }
    }
}


