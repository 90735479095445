
.c-incrementer{
    display: flex;
    height: 55px;
    @include respond(350){
        height: 50px;
     }
    &__button{
        width: 8rem;
        border: 0;
        background: var(--brand-color);
        color: white;
        transition: all .2s;
        &:disabled{
            background-color: #7E7E7E;
        }
        &:active:not(:disabled){
            transform: scale(.95);
        }
    }
    &__input{
        flex: 1;
        position: relative;

        & label{
            position: absolute;
            text-align: center;
            transform: translate(50%, 50%);
            top: -10px;
            right: 50%;
            font-size: 15px;
            color: var(--text-color-light);
            margin: 0;
        }
        & input{
            width: 100%;
            height: 100%;
            text-align: center;
            font-size: 19px;
            border: 0;
            padding-top: 1rem;
        }
    }
}