
/* =========================== */
/*       GLOBAL RESET          */
/* =========================== */
*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

*,
input,
select,
textarea,
option,
button {
    outline: none!important;
}


html {
    font-size: 62.5%;
    scroll-behavior: smooth;
    
}

body {
    font-size: var(--normal-size);
    color: var(--text-color);
    background-color: #F1F5F9;
}


hr{
    border-top: 0px solid #E4E4E4;
    margin: 3rem 0;
    display: block;
}

/* ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}

 ::-webkit-scrollbar-track {
    background: #f1f1f3;
}

 ::-webkit-scrollbar-thumb {
    background: #001c517e;
}

 ::-webkit-scrollbar-thumb:hover {
    background: #435b6c;
} */


/* =========================== */
/*   MATERIAL UI GLOBAL RESET  */
/* =========================== */
