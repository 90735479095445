::ng-deep{
    .c-creditcard{
    background-color: red;
        & .rccs__card{
    
            &--visa{
    
            }
            &__chip{
                background-image: url('../../../assets/images/chip.svg');
            }
        }
    }    
}

.c-creditcard{

    & .rccs{
        &__card{
            height: 15rem !important;
            width: 27rem !important;

            &--front{
                border-radius: 6px !important;
                box-shadow: 0px 3px 46px rgba(0, 0, 0, 0.16) !important;
                & .rccs__number,
                & .rccs__expiry,
                & .rccs__name{
                    opacity: 1 !important;
                }
                & .rccs__name{
                    font-size: 12px !important;
                }
                & .rccs__number{
                    font-size: 16px !important;
                }
                & .rccs__expiry{

                    &__valid{
                        font-size: 10px !important;
                    }   
                    &__value{
                        font-size: 12px !important;
                        text-align: right !important;
                    }

                }
            }

            &--visa{
                & .rccs__issuer{
                    background-image: url('../../../assets/images/visa.svg')!important;
                    width: 5rem;
                    background-size: 100% !important;
                    top: 2rem;
                }
                & .rccs__card__background{
                    background: linear-gradient(#145fa8 0%, #005eb9 29.56%, #0a3054 100%) !important;
                }
            }
            &__background{
               /*  transform: rotate(319deg) !important; */
            }

        }
        
       
        &__chip{
            background-image: url('../../../assets/images/chip.svg')!important;
            left: 2rem !important;
            top: 1.6rem !important;
        }
    }

    & .generalmax{
        margin-top: 4rem;

        & > .form-group:nth-child(1){
            &::before{
                content: url(../../images/icon-visa.svg);
                position: absolute;
                right: 0rem;
            }
            &::after{
                content: url(../../images/icon-mastercard.svg);
                position: absolute;
                right: 4.3rem;
                top: 0;
            }
        }
    }
    & .form-group{
        margin-bottom: 2rem !important;
        position: relative;
        & label{
            font-weight: 700;
            color: #262627;
            margin-bottom: 1rem;
        }
        & input{
            height: 4rem !important;
            border: 1px solid #D4D4D4 !important;
            border-radius: 4px !important;
            font-size: 15px !important;
            padding-left: 2rem !important;

            &::placeholder{
                color: #C0C8CE;
            }
        }

        
    }
    & .tarjeta-det{
        display: flex;
        gap: 1rem;

        & .form-group:last-child{
            flex: 1;
            text-align: right;
        }
    }
    & .checkbox{
        display: flex;
        align-items: center;
        input[type="checkbox"] {
            appearance: none;
            background-color: transparent;
            margin: 0;
            width: 1.15em;
            height: 1.15em;
            border: 1px solid var(--brand-color);
            border-radius: 0.15em;
            transform: translateY(-0.025em);
            display: grid;
            place-content: center;
          }
          
          input[type="checkbox"]::before {
            content: "";
            width: 0.65em;
            height: 0.65em;
            clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
            transform: scale(0);
            transform-origin: bottom left;
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em var(--form-control-color);
            /* Windows High Contrast Mode */
            background-color: var(--brand-color);
          }
          
          input[type="checkbox"]:checked::before {
            transform: scale(1);
          }
          & .form-check-label{
            margin-left: 2.5rem;
            color: #8594A0;
          }
          & .form-check{
            display: flex !important;
            align-items: center;
            padding: 0 !important;
          }
    }
}    