
.c-card-product{
    &__image{
        position: relative;
        min-height: 38rem;
        display: flex;
        @include respond(350){
            min-height: initial;
            height: 20rem;
        }
        @include for-laptop-only{
            height: 20rem;
            min-height: initial;
        }
        & img{
            width: 100%;
            object-fit: cover;
        }
        &__overlay{
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            display: flex;
            align-items: flex-end;
            padding: 2.5rem;
            color: white;
            background: linear-gradient(0deg, #0000008f, transparent);
        }
        & h3{
            font-size: 22px;
            margin: 0;
            line-height: 15px;
            font-weight: 700;
        }
        & h2{
            font-size: 55px;
            font-weight: 800;
        }
    }
    &__body{
        padding: 2rem 2.5rem;
        @include respond(350){
            padding: 2rem 1.5rem;
        }
    }
    &__price{
        display: flex;
        gap: 1rem;
        font-size: 15px;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap-reverse;

        & span{
            color: var(--text-color-light);
            text-decoration: line-through;
        }
        & b{
            font-weight: 800;
        }
    }
    &__state{
        padding: 5px 10px;
        border: 1px solid var(--danger-color);
        border-radius: 5px;
        font-weight: 800;
        font-size: 10px;
        color: var(--danger-color) !important;
        text-decoration: none !important;
        & img{
            position: relative;
            top: -1px;
        }
    }
    &__title{
        font-weight: 800;
        font-size: 30px;
        margin-bottom: 5px;
        line-height: 35px;
    }
    &__detail{
        font-size: 15px;
        color: #7E7E7E;
        margin-bottom: 18px;
    } 
    &__buttons{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: 2rem;
    }
    &__text{
        text-align: center;
        margin-top: 1rem;
        font-size: 14px;
        color: var(--text-color-light);
    }
    &__head{
        padding: 0rem 0rem 2rem 4rem;
        font-size: 17px;
        font-weight: 800;
        text-transform: uppercase;
    }


    &--cart{
        padding: 3rem;
        @include respond(350){
            padding: 2rem;
        }
        & > .d-flex{
            @include respond(350){
                flex-direction: column;
            }
        }

        & .c-card-product__image{
            width: 13rem;
            height: 12rem;
            min-height: initial;
            @include respond(350){
                width: 100%;
                height: 15rem;
            }
        }
        & .c-card-product__body{
            padding: 0;
            padding-left: 2rem;
            flex: 1;
            @include respond(350){
                padding: 2rem 0 0 0;
            }
        }
        & .c-card-product__title{
            margin-top: 5px;
            font-size: 25px;
            line-height: 28px;
        }
        & .c-card-product__detail{
            font-size: 12px;
        }
        & .c-incrementer{
            margin-top: 2rem;
        }
    }
}

