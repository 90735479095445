.c-modal{
    height: 100vh;
    background: #F2F2F2;
    padding: 4rem 2rem 1rem 2rem;
    display: flex;
    flex-direction: column;
    overflow-x: auto;

    &__header{
        display: flex;
        justify-content: space-between;
       
        max-width: 80rem;
        margin: 0 auto;
        width: 100%;
        margin-bottom: 2rem;
    }
    &__title{
        font-size: 15px;
        font-weight: 800;
        text-transform: uppercase;
    }
    &__body{
        flex: 1;
        max-width: 80rem;
        margin: 0 auto;
        width: 100%;
    }
    &__footer{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        max-width: 80rem;
        margin: 0 auto;
        width: 100%;
    }
}

.c-popup{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    max-width: 50rem;
    display: flex;
    flex-direction: column;
    
    margin: 2rem auto;
    box-shadow: 1px 1px 11px 0px #a1a1a163;

    @include respond(600){
        margin: 0 auto;
    }

    &__body{
        padding: 2rem 2rem 0rem 2rem;
        flex: 1;
        overflow-y: auto;

        & p{
            margin-bottom: 2rem;
        }
        & h4{
            font-weight: 700;
        }
    }
    &__footer{
        background: white;
    padding: 1rem 2rem 2rem 2rem;
    }
}