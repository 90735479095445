@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?fpe630');
  src:  url('fonts/icomoon.eot?fpe630#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?fpe630') format('truetype'),
    url('fonts/icomoon.woff?fpe630') format('woff'),
    url('fonts/icomoon.svg?fpe630#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow:before {
  content: "\e907";
  color: #8594a0;
}
.icon-place:before {
  content: "\e908";
  color: #c71e23;
}
.icon-email:before {
  content: "\e904";
  color: #ce2127;
}
.icon-support:before {
  content: "\e905";
  color: #ce2127;
}
.icon-phone:before {
  content: "\e906";
  color: #ce2127;
}
.icon-check:before {
  content: "\e900";
  color: #41ce8b;
}
.icon-clock:before {
  content: "\e901";
  color: #ce6f00;
}
.icon-cart:before {
  content: "\e902";
  color: #fff;
}
.icon-smartphone:before {
  content: "\e903";
  color: rgba(126, 126, 126, 0.38);
}
