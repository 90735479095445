
.c-form-group{
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;

    &__label{
        margin-bottom: 1rem;
        font-size: 15px;
        font-weight: 700;

        &--small{
            font-size: 13px;
            font-weight: 400;
            margin-bottom: 0.6rem;
        }
    }
    &__input{
        height: 4rem;
        padding: 1rem;
        font-size: 15px;
        border: 1px solid #D4D4D4;
        border-radius: 4px;

        &::placeholder{
            color: #A5A6AD;
        }
        &:focus{
            border-color: #3B86FF;
        }
    }
    &__select{
        & .select2-selection {
            height: 4rem !important;
            border: 1px solid #d9d9d9 !important;
            transition: all .2s;
            &:focus{
                border-color: var(--brand-color) !important;
            }
        }
        & .select2-container--open{
            border: 1px solid #3B86FF;
            border-radius: 4px;
        }
        & .select2-selection__rendered{
            display: flex!important;
            align-items: center!important;
            padding-left: 2rem!important;
            font-size: 13px!important;
        }
        & .select2-results__option{
            font-size: 12px !important;
            &--highlighted{
                background: var(--brand-color) !important;
            }
        }
    }
    &__textarea{
        border: 1px solid #d4d4d4;
        border-radius: 4px;
        padding: 1rem;
        font-size: 14px;
        &:focus{
            border-color: #3B86FF;
        }
    }

    &__radio{
        position: relative;
        background: #8b8b8b;
        height: 4.3rem;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.7rem;
        & input{
            position: absolute;
            right: 2rem;
            &:checked + label{
                background-color: var(--brand-color);
            }
            &:after {
                width: 15px;
                height: 15px;
                border-radius: 15px;
                top: -2px;
                left: -1px;
                position: relative;
                background-color: white;
                content: '';
                display: inline-block;
                visibility: visible;
                border: 4px solid white;
            }
            &:checked:after {
                background-color: var(--brand-color);
            }
        }
        & label{
            flex: 1;
            height: 100%;
            margin: 0;
            display: flex;
            align-items: center;
            font-size: 15px;
            color: white;
            padding-left: 2rem;

        }
    }
}