.u-gap-1 {
    gap: 1rem;
}
.u-gap-2 {
    gap: 2rem;
}
.u-gap-3 {
    gap: 3rem;
}

.u-main-title{
    font-size: 17px;
    margin-bottom: 2rem;
    font-weight: 500;
}
.text-center-force {
    text-align: center !important;
}
.u-text-underline{
    text-decoration: underline;
}
.u-table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}
.u-primary-color {
    color: var(--primary-color);
    &::before {
        color: var(--primary-color);
    }
}
.u-secondary-color {
    color: var(--secondary-color);
    &::before {
        color: var(--secondary-color);
    }
}




.u-br-r {
    border-right: 1px solid #EFEFEF;
}
.u-br-b {
    border-bottom: 1px solid #EFEFEF;
}
.u-br-t{
    border-top: 1px solid #EFEFEF;
}
.u-normal-size {
    font-size: var(--normal-size);
}

.u-bigSize2 {
    font-size: var(--big-size-2);
}

.u-big-size {
    font-size: var(--big-size);
}
.u-max-w-initial{
    max-width: initial;
}
.u-small-size {
    font-size: var(--small-size);
}
.u-smaller-size{
    font-size: 11px;
    line-height: 13px;
}

.u-text-color {
    color: var(--text-color);
}
.u-text-color-light {
    color: var(--text-color-light);
}

.u-font-bold {
    font-weight: bold;
}

.u-text-normal {
    font-weight: normal;
}

.text-center {
    text-align: center;
}
.text-center--force {
    text-align: center !important;
}

.text-right {
    text-align: right;
}

.visible {
    visibility: visible;
}

.invisible {
    visibility: hidden;
}

.flex-1 {
    flex: 1;
}

.u-data-group{
    margin-bottom: 2rem;
    font-size: var(--normal-size);
    &__title{
        font-weight: 600;
        margin-bottom: 2px;
    }
    &__data{
        font-weight: 500;
    }
}
/* //////////////////* /
/*     POSITION       */


/* ///////////////// */

.d-none {
    display: none;
}

.d-none--force {
    display: none !important;
}

.d-block {
    display: block;
}

.d-flex {
    display: flex;
}

.flex-column {
    flex-direction: column;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-nowrap {
    flex-wrap: nowrap;
}

.flex-grow-1 {
    flex-grow: 1;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-end {
    justify-content: flex-end;
}

.justify-content-between {
    justify-content: space-between;
}

.justify-content-around {
    justify-content: space-around;
}

.align-items-end {
    align-items: flex-end;
}

.align-items-center {
    align-items: center;
}

.align-content-center {
    align-content: center;
}

.align-content-between {
    align-content: space-between;
}

.align-content-around {
    align-content: space-around;
}

.position-relative {
    position: relative;
}

.position-absolute {
    position: absolute;
}

.position-fixed {
    position: fixed;
}

.overflow-auto {
    overflow: auto;
}

.overflow-hidden {
    overflow: hidden;
}

.shadow-none {
    box-shadow: none;
}


/* //////////////////* /
/*  WIDTH / HEIGHT    */


/* ///////////////// */

.w-25 {
    width: 25%;
}

.w-50 {
    width: 50%;
}

.w-75 {
    width: 75%;
}

.w-100 {
    width: 100%;
}

.w-auto {
    width: auto;
}

.h-25 {
    height: 25%;
}

.h-50 {
    height: 50%;
}

.h-75 {
    height: 75%;
}

.h-100 {
    height: 100%;
}

.h-auto {
    height: auto;
}

.mw-100 {
    max-width: 100%;
}

.mh-100 {
    max-height: 100%;
}

.min-vw-100 {
    min-width: 100vw;
}

.min-vh-100 {
    min-height: 100vh;
}

.vw-100 {
    width: 100vw;
}


/* //////////////////* /
/*     MARGINS       */


/* ///////////////// */

.m-0 {
    margin: 0 !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.m-1 {
    margin: .5rem !important;
}

.m-2 {
    margin: 1rem !important;
}

.m-3 {
    margin: 1.5rem !important;
}

.m-4 {
    margin: 2rem !important;
}

.m-5 {
    margin: 2.5rem !important;
}

.m-1 {
    margin: .5rem !important;
}

.mt-auto {
    margin-top: auto !important;
}

.mt-1 {
    margin-top: .5rem !important;
}

.mt-2 {
    margin-top: 1rem !important;
}

.mt-3 {
    margin-top: 1.5rem !important;
}

.mt-4 {
    margin-top: 2rem !important;
}

.mt-5 {
    margin-top: 2.5rem !important;
}

.mr-auto {
    margin-right: auto !important;
}

.mr-1 {
    margin-right: .5rem !important;
}

.mr-2 {
    margin-right: 1rem !important;
}

.mr-3 {
    margin-right: 1.5rem !important;
}

.mr-4 {
    margin-right: 2rem !important;
}

.mr-5 {
    margin-right: 2.5rem !important;
}

.ml-auto {
    margin-left: auto !important;
}

.ml-1 {
    margin-left: .5rem !important;
}

.ml-2 {
    margin-left: 1rem !important;
}

.ml-3 {
    margin-left: 1.5rem !important;
}

.ml-4 {
    margin-left: 2rem !important;
}

.ml-5 {
    margin-left: 2.5rem !important;
}

.ml-5 {
    margin-left: 2.5rem !important;
}

.mb-auto {
    margin-bottom: auto !important;
}

.mb-1 {
    margin-bottom: .5rem !important;
}

.mb-2 {
    margin-bottom: 1rem !important;
}

.mb-3 {
    margin-bottom: 1.5rem !important;
}

.mb-4 {
    margin-bottom: 2rem !important;
}

.mb-5 {
    margin-bottom: 2.5rem !important;
}


/* //////////////////* /
/*     PADDINGS       */


/* ///////////////// */

.p-0 {
    padding: 0 !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.p-1 {
    padding: .5rem !important;
}

.p-2 {
    padding: 1rem !important;
}

.p-3 {
    padding: 1.5rem !important;
}

.p-4 {
    padding: 2rem !important;
}

.p-5 {
    padding: 2.5rem !important;
}

.p-1 {
    padding: .5rem !important;
}

.pt-1 {
    padding-top: .5rem !important;
}

.pt-2 {
    padding-top: 1rem !important;
}

.pt-3 {
    padding-top: 1.5rem !important;
}

.pt-4 {
    padding-top: 2rem !important;
}

.pt-5 {
    padding-top: 2.5rem !important;
}

.pr-1 {
    padding-right: .5rem !important;
}

.pr-2 {
    padding-right: 1rem !important;
}

.pr-3 {
    padding-right: 1.5rem !important;
}

.pr-4 {
    padding-right: 2rem !important;
}

.pr-5 {
    padding-right: 2.5rem !important;
}

.pl-1 {
    padding-left: .5rem !important;
}

.pl-2 {
    padding-left: 1rem !important;
}

.pl-3 {
    padding-left: 1.5rem !important;
}

.pl-4 {
    padding-left: 2rem !important;
}

.pl-5 {
    padding-left: 2.5rem !important;
}

.pl-5 {
    padding-left: 2.5rem !important;
}

.pb-1 {
    padding-bottom: .5rem !important;
}

.pb-2 {
    padding-bottom: 1rem !important;
}

.pb-3 {
    padding-bottom: 1.5rem !important;
}

.pb-4 {
    padding-bottom: 2rem !important;
}

.pb-5 {
    padding-bottom: 2.5rem !important;
}