.c-footer{
    position: fixed;
    width: 100%;
    max-width: 60rem;
    background: var(--brand-color);
    color: white;
    display: flex;
    padding: 2rem 3rem;
    justify-content: space-between;
    bottom: 4rem;
    border-radius: 0 0 1rem 1rem;
    @include respond(350){
        padding: 2rem 2rem;
    }

    @include respond(768){
        border-radius: 0;
        bottom: 0;
        max-width: initial;
    }
    &__item{
        font-size: 15px;
        font-weight: 700;
        text-transform: uppercase;
    }
    &__price{
        font-size: 15px;
        font-weight: 700;
    }
    &__action{
        text-align: center;
        font-size: 12px;
    }
    &__info{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}