:root {
    --brand-color:#CE2127;
    --primary-color: #3FCED2;
    --secondary-color: #283A7E;
    --tertiary-color: #EF4D37;
    --text-color: #262627;
    --text-color-light: #7E7E7E;
    --border-color: #E4E4E4;
    --normal-size: 14px;
    --small-size: 12px;
    --small-size-2: 10px;
    --big-size: 16px;
    --big-size-2: 18px;
    --big-size-3: 26px;

    --warning-color: #C36100;
    --warning-color-bg: #FFE7D0;
    --danger-color: #CE2127;
    --danger-color-bg: #FFEEEE;
    --success-color: #176521;
    --success-color-bg:#D9FFDE;
}

/* @media screen and (min-width: 1450px) {
    :root {
        --normal-size: 16px;
        --small-size: 13px;
        --small-size-2: 12px;
        --big-size: 18px;
        --big-size-2: 20px;
        --big-size-3: 28px;
    }
} */