.l-main{
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--brand-color);
    padding: 2rem;
    overflow: hidden;
    height: 100vh;
    @include respond(768){
        padding: 0;
        background-color: white;
    }
    &__wrapper{
        background: white;
        box-shadow: 1px 1px 8px 6px #21252930;
        width: 60rem;
        height: calc(100% - 4rem);
        border-radius: 2rem;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        @include respond(768){
           height: 100%;
           border-radius: 0;
           box-shadow: none;
           width: 100%;
        }
        @include respond(350){
           overflow: auto;
        }
    }
    &__content{
        overflow-x: auto;
        height: 100%;
        padding-bottom: 17rem;
        scroll-behavior: smooth;
        @include respond(350){
            overflow-x: initial;
            margin-bottom: 17rem;
         }
    }
}
