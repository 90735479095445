.modal-dialog {
  width: 100vw !important;
  max-width: none !important;
  height: 100% !important;
  margin: 0 !important;
  overflow: hidden;
}
.modal-content{
  border: 0 !important;
}

/* ====================== */
/*        BASE SASS      */
/* ====================== */
@import "./assets/sass/base/variables";
@import "./assets/sass/base/base";
@import "./assets/sass/base/main";
@import "./assets/sass/base/flexboxgrid";


/* ====================== */
/*     COMPONENTS SASS    */
/* ====================== */
@import "./assets//sass/components/layout";
@import "./assets//sass/components/button";
@import "./assets//sass/components/header";
@import "./assets//sass/components/card";
@import "./assets//sass/components/incrementer";
@import "./assets//sass/components/footer";
@import "./assets//sass/components/wizard";
@import "./assets//sass/components/form";
@import "./assets//sass/components/modal";
@import "./assets//sass/components/creditcard";
@import "./assets//sass/components/loader";
@import "./assets//sass/components/services";



/* ====================== */
/*       WDIGET SASS      */
/* ====================== */
@import "./assets/sass/base/utilities";

