

.c-services{
    background: #E8E8E8;

    & .card{
        margin-top: 3rem;
        margin-left: -2rem;
        margin-right: -2rem;
    }

    & .card-header{
        background: #E8E8E8;
        border: 0;
        border-bottom: 1px solid #D9D9D9;
        padding: 0;

        & button{
            width: 100%;
            text-align: left;
            padding: 2rem 2rem;
            text-decoration: none;

            &.collapsed{
                & .c-services__header__icon{
                    transform: rotate(0deg);
                }
            }
        }
    }
    &__header{
        display: flex;
        gap: 1rem;
        align-items: center;

        & .icon-support{
            font-size: 2rem;
        }
        &__title{
            font-size: 14px;
            font-weight: 700;
            color: #8594A0;
        }
        &__icon{
            margin-left: auto;
            transform: rotate(180deg);
            transition: all .2s;
        }
    }
    & .card-body{
        padding-top: 0;
    background: #e8e8e8;
    }
    &__item{
        padding: 1.5rem 1rem;
        border-bottom: 1px solid #D9D9D9;
        color: #8594A0;
        font-size: 13px;
        font-weight: 400;
        display: flex;

        &__icon{
            width: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 1.5rem;
        }
    }
   &__link{
    display: block;
    margin-top: 1rem;
    width: 100%;
    text-align: left;
    padding: 1rem 2rem;
    background: none;
    border: none;
    font-size: 13px;
    font-weight: 400;
    text-decoration: underline;
    color: #222222;
   }
}