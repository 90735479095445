

.c-header{
    display: flex;
    align-items: center;
    background: #F8F8F8;
    padding: 3rem;
    @include respond(350){
        padding: 2rem;
    }

    &__info{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2rem;
    }
    &__logo{
        width: 5rem;
        height: 5rem;
        box-shadow: 0px 3px 6px #00000033;
        border-radius: 50%;
        object-fit: cover;
    }
    &__subtitle{
        font-size: 13px;
        line-height: 16px;
    }
    &__title{
        font-size: 15px;
        font-weight: 800;
    }
    &__cart{
        width: 45px;
        height: 45px;
        border-radius: 6px;
        border: 0;
        background: var(--brand-color);
        position: relative;
        box-shadow: 0px 3px 6px #00000026;
        font-size: 19px;
        margin-left: auto;
        transition: all .2s;
        &:active{
            transform: scale(.95);
        }

        &__badge{
            position: absolute;
            bottom: -8px;
            right: -8px;
            font-size: 12px;
            font-weight: 800;
            width: 19px;
            height: 19px;
            background: white;
            border-radius: 50%;
            box-shadow: 0px 3px 6px #00000026;
        }
    }
}


.c-header-categories{
    gap: 1rem;
    width: 100%;
    overflow: auto;
    white-space: nowrap;
    flex-wrap: nowrap !important;
    padding: 1.5rem 1.5rem 1.9rem 1.5rem !important;
    &::-webkit-scrollbar{
        -webkit-appearance: none;
    }
    &::-webkit-scrollbar {
        height: 0;
    }

    &__item{
        padding: 0 1rem;
        font-size: 11px;
        font-weight: 800;
        text-transform: uppercase;
        position: relative;
        color: #262627;
        transition: all .2s;
        &::after{
            content: "";
             width: 60%;
             background-color: transparent;
             border-radius: 1rem;
             height: 2px;
             position: absolute;
             bottom: -4px;
             left: 50%;
             transform: translate(-50%, 50%);
        }
        &:hover{
            color: var(--brand-color);
            text-decoration: none;

            
        }
        &.active{
            color: var(--brand-color);

            &::after{
                background-color: var(--brand-color);
            }
        }
    }
}

.c-header-cart{
    background: #f8f8f8;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 4rem;
    position: relative;
    margin-top: -10px;
    padding-bottom: 2rem;

    &__title{
        color: #262627;
        font-size: 20px;
        margin: 0;
        font-weight: 800;
        text-transform: uppercase;
    }
}