.c-btn{
    height: 55px;
    border: 0;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 700;
    transition: all .2s;
    border: 1px solid transparent;
    @include respond(350){
        height: 50px;
     }
    &:active{
        transform: scale(.95);
    }
    & .mat-button-wrapper span{
        font-size: 10px;
    }
    &--primary{
        background: var(--brand-color);
        color: white; 
    }
    &--outline{
        color: var(--brand-color);
        border-color: var(--brand-color);
        background-color: white;
    }
    &--outline-white{
        color: white;
        border-color: white;
        background-color: var(--brand-color);
    }
    &--dashed{
        border: 1px dashed;
    }

    &--small{
        height: initial;
        padding: 1rem 2rem;
        border-radius: 6px;
    }
    &--smaller{
        font-size: 10px;
        padding: 0.8rem 2rem;
    }
}

